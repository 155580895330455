import { AuthenticationStatus } from 'state-domains/domain/authentication/types';
import {
    usedNotificationActions,
    usedPreferencesActions,
} from 'state-domains/domain/notifications/used-actions';
import { ERROR_LOGOUT } from 'state-domains/types/actionTypes';

import { buildOAuth2LoginUrl, buildOAuth2LogoutUrl, buildOAuth2TokenUrl } from '../../network/urls';

const ERROR_LOGOUT_TIMEOUT = 5000;

export const authInterceptor = (dispatch: any) => (next: any) => (action: any) => {
    const payload = action?.payload;
    const endPointName = action?.meta?.arg?.endpointName ?? '';

    // Don't intercept 401/403 for notification or their preferences related actions
    let isNotificationPreferencesRelatedAction = false;
    if (action?.type) {
        const actionName = action.type.split('.');
        if (
            usedNotificationActions.includes(actionName[0]) ||
            usedPreferencesActions.includes(actionName[0])
        ) {
            isNotificationPreferencesRelatedAction = true;
        }
    }

    const excludedActions = [
        'LOAD_MAP_TOKEN/loadMapTokenFail',
        'LOAD_EVO_WORKSPACES.failed',
        'LOAD_DOWNHOLE_COLLECTIONS.failed',
        'IMAGO_CHECK_CONNECTION.failed',
        'IMAGO_SET_CONNECTION_STATUS.failed',
        'EVO_CONFIGURATION.failed',
        'IMAGO_SYNC.failed',
    ];

    const isUnauthorized = (status: number, message: string) =>
        (status === 401 || status === 403) && !message?.includes(buildOAuth2TokenUrl());

    if (
        (isUnauthorized(payload?.status, payload?.message) ||
            isUnauthorized(payload?.error?.status, payload?.error?.message)) &&
        !payload?.preventOAuth2LoginRedirection &&
        !isNotificationPreferencesRelatedAction
    ) {
        const authStatus = dispatch.getState().authentication.authenticationStatus;
        const loggedOutState = dispatch.getState().authentication.loggedOutState;

        if (
            authStatus === AuthenticationStatus.Authenticated &&
            endPointName !== 'loadAzureMapToken'
        ) {
            if (excludedActions.includes(action?.type)) {
                dispatch.dispatch({
                    type: action.type,
                    payload: { message: 'Unauthorized' },
                });
            } else {
                setTimeout(() => dispatch.dispatch({ type: ERROR_LOGOUT }), ERROR_LOGOUT_TIMEOUT);
            }
        } else if (authStatus === AuthenticationStatus.NotAuthenticated) {
            window.location.href = loggedOutState
                ? buildOAuth2LogoutUrl()
                : `${buildOAuth2LoginUrl()}?returnUrl=${encodeURIComponent(window.location.pathname)}`;
        }
    } else {
        next(action);
    }
};
